@import '../../style/variables.scss';

.ButtonPage{
  height: calc(100% - #{$headerHeight} - 80px);
  &-Header {
    color: #FFA9E8;
    font-size: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 80px;
    & &-Center {
      grid-column: 2/3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & &-Right {
      grid-column: 3/4;
      display: flex;
      justify-content: flex-end;
      &-Button {
        border: 3px solid #FFA9E8;
        background-color: #2e3736;
        color: #FFA9E8;
        font-size: 1em;
        transition: background-color 0.5s ease;
        transition: color 0.5s ease;
      }
    }
  }

  &-Two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
  }

  &-Three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
  }

  &-Four {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 67% 30%;
    height: 100%;
  }

  a { 
    margin: 15px;
    text-decoration: none;
  }

  & &-Button {
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 15px;
    border-width: 5px;
    border-color: #FFA9E8;
    background-color: #2e3736;
    color: #FFA9E8;
    font-size: 2em;
    transition: background-color 0.5s ease;
    transition: color 0.5s ease;
  }

  & &-Button:hover {
    background-color:#3e6990;
    color: #b2ffa9;
  }
}