.TableDialog{
  &-Form {
    padding: 20px;
    background: #2E3736;
    &-Buttons{
      padding-top: 20px;
      display: grid;
      grid-template-columns: 65% 25%;
      grid-column-gap: 10%;
    }
    & div {
      color: #FFA9E7;
    }
    & p {
      color: white;
    }
    & input {
      color: #FFA9E7;
    }
    & &-Input {
      label {
        color: white;
      }
    }
  }
}

.dateFieldPicker {
  svg {
    color: white;
  }
}