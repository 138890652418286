.TablePage {
  color: white;
  &-Title {
    font-size: 2em;
    color: #FFA9E8;
    text-align: center;
  }
  &-Buttons {
    display: flex;
    justify-content: flex-end;
    padding-left: 1%
  }
  & &-Button {
    color: #FFA9E8;
    transform: scale(2);
    margin: 0px 6px;
  }
  &-Table {
    background: #2E3736;

    & &-Cell {
      padding: '4px 10px 4px 10px';
      color: #FFA9E7;
    }

    &-Actions {
      display: flex;
      justify-content: space-around;
    }
    & &-Button {
      height:100%;
      width: auto;
      padding: 8px;
    }
  }
}

a {
  text-decoration: none;
}

