body {
  background-color: #2e3736;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  height: 98vh; }

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: calc(98vh - 49px); }
  .Login input {
    color: white !important; }
  .Login-Name {
    font-family: Myriad Pro;
    font-size: 2em;
    font-weight: bold; }
  .Login-Logo {
    height: 50vh; }
  .Login-Message {
    font-size: 1.25em; }
  .Login-Form {
    width: 50vh; }
    .Login-Form div::after {
      border-bottom: 2px solid white !important; }
    .Login-Form button {
      background-color: #00A5CF; }
  .Login .Login-Field {
    margin-bottom: 1em; }
  .Login .Login-Button {
    margin-top: 1em; }

.TablePage {
  color: white; }
  .TablePage-Title {
    font-size: 2em;
    color: #FFA9E8;
    text-align: center; }
  .TablePage-Buttons {
    display: flex;
    justify-content: flex-end;
    padding-left: 1%; }
  .TablePage .TablePage-Button {
    color: #FFA9E8;
    -webkit-transform: scale(2);
            transform: scale(2);
    margin: 0px 6px; }
  .TablePage-Table {
    background: #2E3736; }
    .TablePage-Table .TablePage-Table-Cell {
      padding: '4px 10px 4px 10px';
      color: #FFA9E7; }
    .TablePage-Table-Actions {
      display: flex;
      justify-content: space-around; }
    .TablePage-Table .TablePage-Table-Button {
      height: 100%;
      width: auto;
      padding: 8px; }

a {
  text-decoration: none; }

.Header {
  width: calc(100% - 2px);
  background-color: #2e3736;
  display: flex;
  align-items: center;
  padding-left: 2px; }
  .Header-Logo {
    height: 49px; }
  .Header .Header-Button {
    font-size: 1.5em;
    margin: 0px 10px; }
  .Header .testSite {
    color: yellow; }

.TableDialog-Form {
  padding: 20px;
  background: #2E3736; }
  .TableDialog-Form-Buttons {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 65% 25%;
    grid-column-gap: 10%; }
  .TableDialog-Form div {
    color: #FFA9E7; }
  .TableDialog-Form p {
    color: white; }
  .TableDialog-Form input {
    color: #FFA9E7; }
  .TableDialog-Form .TableDialog-Form-Input label {
    color: white; }

.dateFieldPicker svg {
  color: white; }

.ButtonPage {
  height: calc(100% - 49px - 80px); }
  .ButtonPage-Header {
    color: #FFA9E8;
    font-size: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 80px; }
    .ButtonPage-Header .ButtonPage-Header-Center {
      grid-column: 2/3;
      display: flex;
      align-items: center;
      justify-content: center; }
    .ButtonPage-Header .ButtonPage-Header-Right {
      grid-column: 3/4;
      display: flex;
      justify-content: flex-end; }
      .ButtonPage-Header .ButtonPage-Header-Right-Button {
        border: 3px solid #FFA9E8;
        background-color: #2e3736;
        color: #FFA9E8;
        font-size: 1em;
        -webkit-transition: background-color 0.5s ease;
        transition: background-color 0.5s ease;
        -webkit-transition: color 0.5s ease;
        transition: color 0.5s ease; }
  .ButtonPage-Two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%; }
  .ButtonPage-Three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%; }
  .ButtonPage-Four {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 67% 30%;
    height: 100%; }
  .ButtonPage a {
    margin: 15px;
    text-decoration: none; }
  .ButtonPage .ButtonPage-Button {
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 15px;
    border-width: 5px;
    border-color: #FFA9E8;
    background-color: #2e3736;
    color: #FFA9E8;
    font-size: 2em;
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    -webkit-transition: color 0.5s ease;
    transition: color 0.5s ease; }
  .ButtonPage .ButtonPage-Button:hover {
    background-color: #3e6990;
    color: #b2ffa9; }

.DeadPage {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column; }

