@import '../../style/variables.scss';

.Header {
  width: calc(100% - 2px);
  background-color: #2e3736;
  display: flex;
  align-items: center;
  padding-left: 2px;

  &-Logo {
    height: $headerHeight;
  }

  & &-Button {
    font-size: 1.5em;
    margin: 0px 10px;
  }
  .testSite {
    color: yellow;
  }
} 